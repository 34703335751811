import React from 'react'
import { graphql } from 'gatsby'
import { Block, Breadcrumbs, Layout } from '@components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import BlockWrapper from '../pageBuilder/wrapper'

const IndexPage = ({ data, pageContext }) => {
  const { moduleArea, seoMetaTags } = data.home
  return (
    <Layout pageType="index">
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      {pageContext?.breadcrumbs && (
        <Block gutters>
          <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} />
        </Block>
      )}

      <BlockWrapper blocks={moduleArea} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    home: datoCmsHome {
      id
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      moduleArea {
        ... on DatoCmsAccordion {
          ...Accordion
        }
        ... on DatoCmsBanner {
          ...Banner
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsCardGrid {
          ...CardGrid
        }
        ... on DatoCmsCardSlider {
          ...CardSlider
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsCta {
          ...Cta
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsImageWithContent {
          ...ImageWithContent
        }
        ... on DatoCmsSlider {
          ...Slider
        }
        ... on DatoCmsVideo {
          ...Video
        }
        ... on DatoCmsVideoWithContent {
          ...VideoWithContent
        }
      }
    }
    allSitePage {
      nodes {
        ...MenuHelpersAllPages
      }
    }
  }
`
